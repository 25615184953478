import React from "react"

import { Helmet } from "react-helmet"

import Global from "./src/Global"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapRootElement = ({ element }) => {
  return (
    <>
      {process.env.GATSBY_BUILD_PROFILE !== "production" ? null : (
        <Helmet>
          <script
            type="application/javascript"
            id="_bownow_ts"
            src="https://contents.bownow.jp/js/UTC_b40cdf40da84a2bb094f/trace.js"
          />
        </Helmet>
      )}
      <Global>{element}</Global>
    </>
  )
}
