import React from "react"

import { ThemeProvider } from "@material-ui/core"

import { FirebaseContextProvider } from "./components/FirebaseContext"
import theme from "./styles/theme"

import "fontsource-poppins/700-normal.css"
import "fontsource-noto-sans-jp"
import "fontsource-noto-sans-jp/700-normal.css"
import "fontsource-noto-sans-jp/900-normal.css"

import "./styles/global.css"

const Global: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <FirebaseContextProvider>{children}</FirebaseContextProvider>
    </ThemeProvider>
  )
}

export default Global
